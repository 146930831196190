<template>
    <div>
        <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-5 mb-3">
                <v-alert text dense color="#8061c2" border="left" class="pl-5">
                    <h1 class="title mt-1">Detalles de las conversiones</h1>
                    <p class="desc mt-0 mb-1">Listado de total de conversiones y ganancias.</p>
                </v-alert>
            </v-col>

            <!-- Search Bar y Filtros -->
            <v-row class="mx-3">
                <v-col cols="12" md="8" class="pr-md-2 px-0">
                    <v-text-field v-model="search" @input="fetchConversions" color="#8061c2" label="Buscar" append-icon="mdi-magnify" single-line hide-details outlined class="mb-2 mx-0 px-0"></v-text-field>
                </v-col>

                <v-col cols="12" md="2" class="pr-md-2 px-0">
                    <v-select v-model="sortField" :items="sortFieldOptions" label="Ordenar por" outlined hide-details @change="fetchConversions"></v-select>
                </v-col>

                <v-col cols="12" md="2" class="pr-md-2 px-0">
                    <v-select v-model="sortOrder" :items="sortOrderOptions" label="Orden" outlined hide-details @change="fetchConversions"></v-select>
                </v-col>
            </v-row>

            <!-- Tabla de datos -->
            <v-data-table :headers="headers" :items="conversions" :loading="loading" class="elevation-1 mx-3 px-2 py-4" :items-per-page="perPage" :server-items-length="totalConversions" :page.sync="page" :footer-props="{
              itemsPerPageOptions: [50, 100, 200, -1],
              showFirstLastPage: true
            }" @update:page="fetchConversions" @update:items-per-page="changePerPage">
                <!-- Columnas y Templates -->

                <template v-slot:item.logo_path="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="40" class="clickable-avatar" @click="showLogo(item.logo_path)" v-bind="attrs" v-on="on">
                                <v-img :src="item.logo_path" v-if="item.logo_path"></v-img>
                                <v-avatar v-else color="black">
                                    <span class="white--text">{{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}</span>
                                </v-avatar>
                            </v-avatar>
                        </template>
                        <span>Ver Imagen</span>
                    </v-tooltip>
                </template>

                <!-- Botones de acciones -->
                <template v-slot:item.actions="{ item }">
                    <!-- Botón para impersonar usuario -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on" @click="goToStats(item.platform)">
                                <v-icon>mdi-tab-search</v-icon>
                            </v-btn>
                        </template>
                        <span>Visualizar conversiones</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.total_payment="{ item }">
                    $ {{ item.total_payment }} USD
                </template>

                <template v-slot:item.total_payment_creator="{ item }">
                    $ {{ item.total_payment_creator }} USD
                </template>
            </v-data-table>
        </v-col>
    </div>
    </template>

    <script>
    import axios from '@/axios.js';
    import moment from 'moment';

    export default {
        name: 'ConversionsDetails',
        data() {
            return {
                conversions: [],
                search: '',
                loading: true,
                dialog: false,
                selectedLogo: null,
                page: 1,
                perPage: 50,
                totalConversions: 0,
                sortField: 'total_payment', // Cambiado a total_payment por defecto
                sortFieldOptions: [
                    {
                        text: 'Total Conversiones',
                        value: 'total_conversions'
                    },
                    {
                        text: 'Cantidad de Conversiones',
                        value: 'conversions_count'
                    },
                    {
                        text: 'Pago Total Plataforma',
                        value: 'total_payment'
                    },
                    {
                        text: 'Pago Total Creadores',
                        value: 'total_payment_creator'
                    }
                ],
                sortOrder: 'desc',
                sortOrderOptions: [{
                        text: 'Mayor a menor',
                        value: 'desc'
                    },
                    {
                        text: 'Menor a mayor',
                        value: 'asc'
                    },
                ],
                headers: [{
                        text: 'Plataforma',
                        value: 'platform',
                        align: 'start',
                    },
                    {
                        text: 'Cantidad de Conversiones',
                        value: 'conversions_count',
                        align: 'start',
                    },
                    {
                        text: 'Pago Total Plataforma',
                        value: 'total_payment',
                        align: 'start',
                    },
                    {
                        text: 'Pago Total Creadores',
                        value: 'total_payment_creator',
                        align: 'start',
                    },
                    {
                        text: 'Acciones',
                        value: 'actions',
                        sortable: false,
                    },
                ],
            };
        },
        watch: {
            sortField(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.fetchConversions();
                }
            },
            sortOrder(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.fetchConversions();
                }
            }
        },

        created() {
            this.fetchConversions();
        },
        methods: {
            async fetchConversions() {
                this.loading = true;
                try {
                    const response = await axios.get('/admin/get-conversions-details', {
                        params: {
                            page: this.page,
                            per_page: this.perPage,
                            search: this.search,
                            sort_field: this.sortField,
                            sort_order: this.sortOrder
                        },
                    });
                    this.conversions = response.data.data.data; // Accedemos a data.data por la paginación
                    this.totalConversions = response.data.total;
                } catch (error) {
                    console.error('Error fetching conversions:', error);
                    this.$vs.notify({
                        title: 'Error',
                        text: 'No se pudieron cargar las conversiones',
                        color: 'danger',
                        position: 'bottom-center'
                    });
                } finally {
                    this.loading = false;
                }
            },
            changePerPage(newPerPage) {
                this.perPage = newPerPage;
                this.page = 1;
                this.fetchConversions();
            },

            goToStats(platform) {
                const platformUrl = `${window.location.origin}/panel/admin/conversions?platform=${platform}`;
                window.open(platformUrl, '_blank');
            },

            formatDate(date) {
                return moment(date).format('DD/MM/YYYY');
            },
        },
    };
</script>

<style scoped>
    .clickable-avatar:hover {
        transform: scale(1.2);
        transition: transform 0.3s ease;
    }

    .title {
        font-size: 22px !important;
        color: #8061c2;
    }

    .desc {
        color: #737373;
        font-size: 15px;
    }
</style>
